<template>
  <div>
    <v-row v-if="besuch && !checkout" class="pa-0">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          :light="!template.isDark(template.colors.blocks)"
          :dark="template.isDark(template.colors.blocks)"
          class="pb-0 rounded-xl"
        >
          <v-card
            class="rounded-t-xl rounded-b-0 px-4"
            :color="template.colors.inline_secondary_text"
            :style="`background-color: ${template.colors.secondary};`"
          >
            <v-row>
              <v-col cols="auto" align-self="center" class="pb-0">
                <v-icon size="70px" color="success">
                  mdi-ticket
                </v-icon>
              </v-col>
              <v-col class="pb-0">
                <v-row>
                  <v-col
                    cols="auto"
                    class="text-center"
                    align-self="center"
                    v-if="besuch.veranstaltung.id != 'ANDERE'"
                  >
                    <v-icon
                      x-large
                      :color="template.colors.block_text"
                      v-if="besuch.veranstaltung.art == 'Training'"
                    >
                      mdi-strategy
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Wettkampf'"
                      :color="template.colors.block_text"
                    >
                      mdi-podium
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Freundschaftsspiel'"
                      :color="template.colors.block_text"
                    >
                      mdi-soccer
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Meisterschaftsspiel'"
                      :color="template.colors.block_text"
                    >
                      mdi-soccer-field
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Versammlung'"
                      :color="template.colors.block_text"
                    >
                      mdi-calendar-star
                    </v-icon>
                  </v-col>
                  <v-col v-if="besuch.veranstaltung.id == 'ANDERE'">
                    <h3
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      Andere Veranstaltung
                    </h3>
                    <h5
                      v-if="!$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      Andere Veranstaltung
                    </h5>
                    <h3
                      v-if="!$vuetify.breakpoint.smAndUp && publicdata.verein"
                      class="font-weight-bold"
                    >
                      {{ publicdata.verein.verein.name }}
                    </h3>
                    <h2
                      v-if="$vuetify.breakpoint.smAndUp && publicdata.verein"
                      class="font-weight-bold"
                    >
                      {{ publicdata.verein.verein.name }}
                    </h2>
                  </v-col>
                  <v-col v-if="besuch.veranstaltung.id != 'ANDERE'">
                    <h3
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      {{ besuch.veranstaltung.art }}
                    </h3>
                    <h5
                      v-if="!$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      {{ besuch.veranstaltung.art }}
                    </h5>
                    <h2 v-if="$vuetify.breakpoint.smAndUp">
                      <span
                        class="font-weight-bold"
                        v-if="besuch.veranstaltung.art == 'Training'"
                      >
                        {{ besuch.veranstaltung.mannschaft }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Wettkampf' ||
                          besuch.veranstaltung.art == 'Versammlung'
                        "
                      >
                        {{ besuch.veranstaltung.name }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Freundschaftsspiel' ||
                          besuch.veranstaltung.art == 'Meisterschaftsspiel'
                        "
                      >
                        <small>
                          {{ besuch.veranstaltung.mannschaft }} -
                          {{ besuch.veranstaltung.gastmannschaft }}
                        </small>
                      </span>
                    </h2>
                    <h3 v-if="!$vuetify.breakpoint.smAndUp">
                      <span
                        class="font-weight-bold"
                        v-if="besuch.veranstaltung.art == 'Training'"
                      >
                        {{ besuch.veranstaltung.mannschaft }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Wettkampf' ||
                          besuch.veranstaltung.art == 'Versammlung'
                        "
                      >
                        {{ besuch.veranstaltung.name }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Freundschaftsspiel' ||
                          besuch.veranstaltung.art == 'Meisterschaftsspiel'
                        "
                      >
                        <small>
                          {{ besuch.veranstaltung.mannschaft }} -
                          {{ besuch.veranstaltung.gastmannschaft }}
                        </small>
                      </span>
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <v-row justify="center" class="mt-2 px-4">
            <v-col cols="12" class="text-center" align-self="center">
              <small class="text-uppercase">
                {{
                  besuch.weitere_personen.length > 0
                    ? besuch.aktivitaet + ' EINES HAUSHALTS'
                    : besuch.aktivitaet
                }}
              </small>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <h2>{{ besuch.person.vorname }} {{ besuch.person.name }}</h2>
                <h3
                  v-for="(person, index) in besuch.weitere_personen"
                  :key="index"
                >
                  {{
                    person.vorname
                      ? person.vorname + ' ' + person.nachname
                      : person
                  }}
                </h3>
              </div>
              <div v-if="!$vuetify.breakpoint.smAndUp">
                <h3>{{ besuch.person.vorname }} {{ besuch.person.name }}</h3>
                <h4
                  v-for="(person, index) in besuch.weitere_personen"
                  :key="index"
                >
                  {{
                    person.vorname
                      ? person.vorname + ' ' + person.nachname
                      : person
                  }}
                </h4>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="px-4"
            v-touch="{
              left: () => {
                show_timer = !show_timer
                show_timer_transition = false
              },
              right: () => {
                show_timer = !show_timer
                show_timer_transition = true
              },
            }"
          >
            <v-col
              cols="12"
              class="text-center"
              @click="show_timer = !show_timer"
              v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))
                  "
            >
              <v-row justify="center">
                <v-col cols="12">
                  <small class="text-uppercase">Eingecheckt seit</small>
                </v-col>
              </v-row>
              <v-slide-x-transition v-if="show_timer_transition" hide-on-leave>
                <v-row
                  justify="center"
                  v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit)) &&
                    show_timer
                  "
                >
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.hours) }}</h1>
                    <small>STD</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.minutes) }}</h1>
                    <small>MIN</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.seconds) }}</h1>
                    <small>SEK</small>
                  </v-col>
                </v-row>
              </v-slide-x-transition>
              <v-slide-x-reverse-transition
                v-if="!show_timer_transition"
                hide-on-leave
              >
                <v-row
                  justify="center"
                  v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit)) &&
                    show_timer
                  "
                >
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.hours) }}</h1>
                    <small>STD</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.minutes) }}</h1>
                    <small>MIN</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.seconds) }}</h1>
                    <small>SEK</small>
                  </v-col>
                </v-row>
              </v-slide-x-reverse-transition>
              <v-slide-x-transition v-if="show_timer_transition" hide-on-leave>
                <v-row
                  justify="center"
                  v-if="
                    !show_timer &&
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))
                  "
                >
                  <v-col cols="auto">
                    <b>{{ parseDate1(besuch.zutritt.datum) }}</b>
                    <br />
                    <small>TAG</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto">
                    <b>{{ besuch.zutritt.uhrzeit }} UHR</b>
                    <br />
                    <small>UHRZEIT</small>
                  </v-col>
                </v-row>
              </v-slide-x-transition>
              <v-slide-x-reverse-transition
                v-if="!show_timer_transition"
                hide-on-leave
              >
                <v-row
                  justify="center"
                  v-if="
                    !show_timer &&
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))
                  "
                >
                  <v-col cols="auto">
                    <b>{{ parseDate1(besuch.zutritt.datum) }}</b>
                    <br />
                    <small>TAG</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto">
                    <b>{{ besuch.zutritt.uhrzeit }} UHR</b>
                    <br />
                    <small>UHRZEIT</small>
                  </v-col>
                </v-row>
              </v-slide-x-reverse-transition>
            </v-col>
            <v-row
              justify="center"
              v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit_format_sec)) 
                  "
            >
              <v-col cols="auto" class="pr-0">
                <v-icon small v-if="show_timer">mdi-circle</v-icon>
                <v-icon small v-if="!show_timer">mdi-circle-outline</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-icon small v-if="!show_timer">mdi-circle</v-icon>
                <v-icon small v-if="show_timer">mdi-circle-outline</v-icon>
              </v-col>
            </v-row>
          </v-row>
          <v-row
            justify="center"
            class="pa-0"
            v-if="
              besuch.verlassen.datum > timer.datum ||
              (besuch.verlassen.datum == timer.datum &&
                besuch.verlassen.uhrzeit > timer.uhrzeit)
            "
          >
            <v-col cols="12" class="text-center">
              <v-btn
                icon
                :color="template.colors.primary"
                v-if="start_checkout"
                @click="start_checkout = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                :color="
                  start_checkout
                    ? template.colors.success
                    : template.colors.primary
                "
                :style="`color: ${
                  start_checkout
                    ? template.colors.success_text
                    : template.colors.inline_primary_text
                };`"
                rounded
                large
                @click="start_checkout ? check_out() : (start_checkout = true)"
                :loading="checking_out"
              >
                <h3>
                  {{ start_checkout ? 'Bestätigen' : 'Jetzt auschecken' }}
                </h3>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2 px-4">
            <v-col
              cols="12"
              class="text-center"
              v-if="
                besuch.verlassen.datum > timer.datum ||
                (besuch.verlassen.datum == timer.datum &&
                  besuch.verlassen.uhrzeit > timer.uhrzeit)
              "
            >
              <span>
                Du bist automatisch bis
                <span class="font-weight-bold">
                  {{
                    besuch.verlassen.datum > timer.datum
                      ? ' zum ' + parseDate1(besuch.verlassen.datum) + ' um '
                      : ''
                  }}{{ besuch.verlassen.uhrzeit }} Uhr
                </span>
                eingecheckt.
              </span>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
              v-if="
                (besuch.verlassen.datum <= timer.datum &&
                  besuch.verlassen.uhrzeit <= timer.uhrzeit)
              "
            >
              <h3>Seit {{ besuch.verlassen.uhrzeit }} Uhr ausgecheckt.</h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-if="publicdata.verein.hygienekonzept"
      >
        <h1>
          {{
            publicdata.verein.hygienekonzept.custom_header.activated
              ? publicdata.verein.hygienekonzept.custom_header.title
              : 'HYGIENE REGELN'
          }}
        </h1>
        <h3 v-if="!publicdata.verein.hygienekonzept.custom_header.activated">
          {{ publicdata.verein.verein.name }}
        </h3>
        <h4 v-if="publicdata.verein.hygienekonzept.custom_header.activated">
          {{ publicdata.verein.hygienekonzept.custom_header.text }}
        </h4>
        <v-btn
          block
          rounded
          class="my-2"
          :color="template.colors.primary"
          :href="publicdata.verein.hygienekonzept.custom_header.link"
          target="_blank"
          v-if="publicdata.verein.hygienekonzept.custom_header.link"
        >
          {{
            publicdata.verein.hygienekonzept.custom_header.linkname
              ? publicdata.verein.hygienekonzept.custom_header.linkname
              : 'Website öffnen'
          }}
          <v-icon class="ml-2">mdi-open-in-new</v-icon>
        </v-btn>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.testpflicht"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/icon_3g.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>
                {{
                  publicdata.verein.hygienekonzept.ggg_regel
                    ? publicdata.verein.hygienekonzept.ggg_regel.titel
                    : '3G-Regel'
                }}
              </h3>
              <span>
                Es ist zwingend ein negativer COVID-19 Test (nicht älter als
                48h), ein Nachweis über eine vollständige COVID-19 Impfung oder
                ein Nachweis über eine Genesung von COVID-19 (nicht älter als 6
                Monate).
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.abstand.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/abstand.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>{{ publicdata.verein.hygienekonzept.abstand.title }}</h3>
              <span>
                {{ publicdata.verein.hygienekonzept.abstand.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.bereiche.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/bereiche.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>{{ publicdata.verein.hygienekonzept.bereiche.title }}</h3>
              <span>
                {{ publicdata.verein.hygienekonzept.bereiche.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.desinfektion.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/haende.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>
                {{ publicdata.verein.hygienekonzept.desinfektion.title }}
              </h3>
              <span>
                {{ publicdata.verein.hygienekonzept.desinfektion.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.kontakte.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/no_handshake.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>
                {{ publicdata.verein.hygienekonzept.kontakte.title }}
              </h3>
              <span>
                {{ publicdata.verein.hygienekonzept.kontakte.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.maske.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/maske.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>
                {{ publicdata.verein.hygienekonzept.maske.title }}
              </h3>
              <span>
                {{ publicdata.verein.hygienekonzept.maske.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
          v-if="publicdata.verein.hygienekonzept.symptome.activated"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/healthy.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>
                {{ publicdata.verein.hygienekonzept.symptome.title }}
              </h3>
              <span>
                {{ publicdata.verein.hygienekonzept.symptome.text }}
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-if="!publicdata.verein.hygienekonzept"
      >
        <h1>HYGIENE REGELN</h1>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/maske.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>MUND- & NASENSCHUTZ</h3>
              <span>
                Trage auf dem Gelände und vor allem in geschlossenen Räumen
                stets einen Mund- & Nasenschutz
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/abstand.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>HALTE ABSTAND</h3>
              <span>
                Achte auf den Mindestabstand - auch im Zuschauerbereich
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl my-4"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center" align-self="center">
              <v-img src="/img/covid-icons/haende.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>HÄNDE DESINFIZIEREN</h3>
              <span>
                Desinfiziere deine Hände beim Betreten & Verlassen des Geländes
                oder wasche dir für min. 20 Sekunden deine Hände
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl mt-3 mb-4"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center">
              <v-img src="/img/covid-icons/healthy.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>FÜHLST DU DICH FIT?</h3>
              <span>
                Bitte bleibe zu Hause, wenn du dich krank fühlst.
              </span>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          class="rounded-xl my-4"
        >
          <v-row class="pa-4">
            <v-col cols="4" md="3" class="text-center" align-self="center">
              <v-img src="/img/covid-icons/no_handshake.png"></v-img>
            </v-col>
            <v-col cols="8" md="9" class="text-left" align-self="center">
              <h3>VERMEIDE KONTAKT</h3>
              <span>
                Verzichte auf übliche Begrüßungsrituale und Körperkontakt zu
                anderen Personen
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="checkout" style="height: 70vh;">
      <v-col cols="11">
        <h2 class="white--text">Veranstaltung verlassen</h2>
        <p>
          Du hast die Veranstaltung verlassen und deine Zeit wurde erfolgreich
          gespeichert. Danke für deinen Besuch!
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../firebaseConfig'
import store from '../../store'
import logout from '../../auth/auth'

export default {
  name: 'Veranstaltung',
  data() {
    return {
      valid: false,
      checkout: false,
      show_timer: true,
      start_checkout: false,
      checkoutzeit: '',
      checkintimer: '',
      aktuellezeit: '',
      aktuellesdatum: '',
      besuch: '',
      modal2: false,
      checking_out: false,
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            return firebase.auth().signInAnonymously()
          })
      } else {
        store.dispatch('setPublic', {
          name: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
        setTimeout(() => {
          this.load_zuschauer()
        }, 50)
        setTimeout(this.setTime(), 500)
      }
    })
  },
  methods: {
    checkin_timer() {
      var start = this.besuch.zutritt.timestamp
      var end = Date.now()
      var elapsed = end - start
      var difference = new Date(elapsed)
      this.checkintimer = {
        hours: difference.getUTCHours(),
        minutes: difference.getUTCMinutes(),
        seconds: difference.getUTCSeconds(),
      }
      setTimeout(() => {
        this.checkin_timer()
      }, 1000)
    },
    load_zuschauer() {
      if (this.publicdata.verein.id && this.$route.params.zuschauer) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Anwesenheit')
          .doc(this.$route.params.zuschauer)
          .get()
          .then((doc) => {
            this.besuch = doc.data()
            this.checkin_timer()
          })
      } else {
        setTimeout(() => {
          this.load_zuschauer()
        }, 100)
      }
    },
    setTime() {
      var today = new Date()
      this.aktuellezeit =
        this.addNull(parseInt(today.getHours())) +
        ':' +
        this.addNull(parseInt(today.getMinutes()))
      this.aktuellesdatum =
        today.getFullYear() +
        '-' +
        this.addNull(parseInt(today.getMonth()) + 1) +
        '-' +
        this.addNull(parseInt(today.getDate()))
      setTimeout(() => {
        this.setTime()
      }, 30000)
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parseDate1(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.'
    },
    addNull(num) {
      if (num >= 10) {
        return num
      }
      return '0' + num
    },
    check_out() {
      this.checking_out = true
      var today = new Zeit()
      var time = today.getUhrzeit()
      firebase
        .firestore()
        .collection('User')
        .doc(this.publicdata.verein.id)
        .collection('Anwesenheit')
        .doc(this.$route.params.zuschauer)
        .update({
          'verlassen.datum': today.getDatum(),
          'verlassen.uhrzeit': today.getUhrzeit(),
          'verlassen.timestamp': today.getTimestamp(),
        })
        .then(() => {
          this.checkout = true
          this.checking_out = false
        })
    },
    later_check_out() {
      if (this.publicdata.veranstaltung.id != 'andere') {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Veranstaltung')
          .doc(this.publicdata.veranstaltung.id)
          .collection('Anwesenheit')
          .doc(this.$route.params.zuschauer)
          .update({
            verlassen: this.checkoutzeit,
          })
          .then(() => {
            this.checkout = true
            logout.logout()
          })
      } else {
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Anwesenheit')
          .doc(this.$route.params.zuschauer)
          .update({
            verlassen: this.checkoutzeit,
          })
          .then(() => {
            this.checkout = true
            logout.logout()
          })
      }
    },
  },
}
</script>
>
